$popupTransitionDuration: 200ms;

body[data-dialoginit] {
  .popup,
  .popup__overlay {
    // prettier-ignore
    transition: opacity $popupTransitionDuration 0ms, visibility 0ms $popupTransitionDuration;
  }
}

body[data-dialog] .popup__overlay {
  @apply opacity-100 visible;

  // prettier-ignore
  transition: opacity $popupTransitionDuration 0ms, visibility 0ms 0ms;
}

.popup {
  @apply min-w-0 min-h-0 max-w-full max-h-full w-3xl bg-white shadow relative flex flex-col overflow-y-auto invisible opacity-0 col-start-1 row-start-1;

  &__overlay {
    @apply grid fixed z-30 inset-0 items-start justify-items-center content-center invisible opacity-0 px-3 pb-3;

    padding-top: 4.75rem;
    background-color: rgba(0, 0, 0, 0.5);
    grid-template-rows: minmax(0, 100%);
    grid-template-columns: minmax(0, 100%);

    @screen 400 {
      @apply px-6 pb-6;

      padding-top: 5.5rem;
    }

    @screen 900 {
      @apply items-center pt-6;
    }
  }

  &__content {
    @apply p-5;

    @screen 400 {
      @apply p-6;
    }
  }

  &__close-button {
    @apply ml-auto relative h-10 w-10 flex items-center justify-center leading-none transition-bg rounded-full top-2 right-2 bg-primary-500;

    &:hover {
      @apply bg-primary-400;
    }

    &__container {
      @apply h-0 overflow-visible sticky top-0 z-10;
    }

    i,
    .svg-inline--fa {
      @apply inline-block h-6 w-6 text-white;
    }

    @screen 400 {
      @apply h-12 w-12 top-3 right-3;
    }
  }
}
