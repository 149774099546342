@media only screen and (max-width: 899px) {
  .menu--mobile {
    @apply z-50 w-80 fixed top-16 right-0 bg-white overflow-y-scroll pb-16 flex flex-col content-visibility-auto;

    height: calc(100vh - 4rem);
    transform: translateX(20rem);
  }

  $popupTransitionDuration: 200ms;

  body[data-dialoginit] .menu--mobile {
    transition: transform $popupTransitionDuration 0ms;
  }

  body[data-dialog="mob-nav"] #popup_mob-nav.menu--mobile {
    transform: translateX(0);
    transition: transform $popupTransitionDuration 0ms;
  }
}
