@media only screen and (max-width: 899px) {
  .menu--mobile {
    .menu__item {
      &[data-dropdown] {
        grid-template:
          "arrow link"
          "submenu submenu" /
          min-content minmax(0, 1fr);
      }

      &--depth-0[data-dropdown="closed"] {
        @apply pb-0;
      }

      &__arrow--dropdown {
        @apply h-3.5 w-3.5 transform transition-transform text-gray-500;

        &__container {
          @apply pr-3 flex items-center justify-end;
          width: var(--spacing-left);
          grid-area: arrow;

          + .menu__item__link {
            @apply pl-0;
          }
        }
      }
    }

    [data-dropdown-toggle] {
      @apply cursor-pointer;
    }

    [data-dropdown="closed"] {
      > .menu__sub {
        @apply hidden;
      }

      > .menu__item__arrow--dropdown__container > .menu__item__arrow--dropdown {
        @apply -rotate-90;
      }
    }
  }
}
