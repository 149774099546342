.category {
  &__container {
    @apply grid grid-cols-auto-grow-80 gap-y-24 gap-x-8 mt-8 mb-12 list-none;

    li {
      @apply mt-0 flex;
    }

    > article {
      @apply flex flex-col;
    }

    img {
      @apply w-full h-48 object-cover object-center;
    }

    //'h3 a' is for vloerbekleding.net widgets, one day it should be removed
    h3 a {
      @apply no-underline leading-normal;

      &:hover {
        @apply underline;
      }
    }

    p {
      @apply mb-0 overflow-hidden;
    }
  }

  &__after {
    @apply flex flex-col mb-8;

    &__container {
      @apply pt-12 mt-8;
    }

    &__image {
      @apply h-56 w-56 flex-none mx-auto mb-8;
    }

    img {
      @apply object-cover object-center w-full h-full rounded-full;
    }

    @screen 700 {
      @apply flex-row;

      &__image {
        @apply ml-0 mb-0 mr-8;
      }

      &__text {
        @apply self-center;
      }
    }
  }
}
