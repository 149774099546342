[data-locale]:not([data-locale-shown]):not([data-locale-default]) {
  @apply hidden;
}

.locale-switch {
  @apply text-gray-700 font-normal flex items-center transition-text leading-none;

  &:hover {
    @apply text-gray-900;

    .locale-switch__select {
      @apply bg-gray-100 border-gray-200;
    }
  }

  &__select {
    @apply bg-transparent border border-transparent ml-1 cursor-pointer py-1 pl-1 pr-6 rounded transition-colors;

    background-position: calc(100% - 0.5rem);
  }
}
