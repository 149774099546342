.header-banner {
  @apply min-h-36 w-full bg-gray-200 relative flex;

  &__img,
  &__img img {
    @apply absolute left-0 top-0 w-full h-full z-0 object-cover;
  }

  .container {
    @apply flex;

    position: relative !important;
  }

  &__container {
    @apply flex self-center items-center z-10 py-3;

    @screen 400 {
      @apply py-6;
    }

    &--centered {
      @apply w-3xl mx-auto;
    }
  }

  &__title {
    @apply text-primary-500 font-roboto-slab font-bold mb-0 text-3xl;

    &--thumbnail {
      @apply py-4 px-5 rounded;

      background-color: rgba(255, 255, 255, 0.75);
    }
  }
}
