h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  @apply text-primary-500 font-roboto-slab;
}

h1,
.h1 {
  @apply font-normal;

  font-size: 2rem;
}

p {
  line-height: 1.7;
}
