.cookies {
  @apply hidden w-full bg-white border-t shadow fixed bottom-0 max-w-full z-20 content-visibility-auto;

  &__container {
    @apply flex items-center pr-0;

    @screen 900 {
      @apply pr-6;
    }
  }

  &__consent-button {
    @apply flex-none ml-auto w-16 h-16 text-2xl;
  }

  &__info-link,
  &__consent-button {
    @apply text-primary-500;

    &:hover {
      @apply text-primary-400;
    }
  }

  &--active {
    @apply block;
  }
}
