a {
  @apply underline transition-text cursor-pointer text-primary-500;

  &:hover {
    @apply text-primary-300;
  }
}

p {
  a {
    @apply py-2;
  }

  br + a {
    @apply inline-block py-1;
  }
}

ol,
ul {
  @apply pl-5 my-4 overflow-hidden;

  li + li {
    @apply mt-4;
  }
}

.list\:spacing {
  &-none li + li {
    @apply mt-0;
  }

  &-sm li + li {
    @apply mt-2;
  }
}

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}

img {
  @apply object-contain object-center;
}

.alignleft,
.alignright {
  @apply my-2 float-none;
}

img.alignleft,
.alignleft img,
img.alignright,
.alignright img {
  max-width: 100% !important;

  @apply max-h-72 mx-auto;
}

figure.alignleft,
figure.alignright {
  @apply mx-auto;
}

@screen 500 {
  .alignleft {
    margin-right: 1rem !important;

    @apply float-left clear-left;
  }

  .alignright {
    margin-left: 1rem !important;

    @apply float-right clear-right;
  }

  img.alignleft,
  .alignleft img,
  img.alignright,
  .alignright img {
    max-height: 100vh !important;
  }
}

.alignnone,
.aligncenter,
figure.alignnone img,
figure.aligncenter img {
  width: 100% !important;
  height: auto !important;
}

.thumbnail__default-img {
  object-position: left top !important;

  &--solvari {
    @apply bg-gray-100 p-4;

    object-fit: contain !important;
    object-position: center !important;
  }
}

.wp-caption-text,
.wp-caption-dd {
  @apply text-base text-gray-800 text-center mb-2;

  @screen 400 {
    @apply text-sm;
  }
}

.wp-caption {
  @apply max-w-full;
}

a[name]:not([href]) {
  @apply relative inline -top-20;
}

code {
  @apply border-l-6 border-red-500 text-gray-800 bg-gray-100 block px-5 py-6 mt-4 mb-8 text-base;
}

hr {
  @apply border-t-0 border-b my-8;
}

button {
  outline: 0 !important;
}

[class*="list\:fa"] {
  @apply list-none pl-0;

  li {
    @apply relative pl-6;
  }

  .svg-inline--fa,
  i {
    @apply absolute left-0 h-6;
  }
}

.list\:columns {
  @apply grid grid-cols-2 gap-y-4 gap-x-8;

  @screen 600 {
    @apply grid-cols-auto-grow-48;
  }

  li {
    @apply mt-0;
  }
}

@screen 500 {
  .list\:inline {
    @apply flex flex-wrap;

    li {
      &:not(:last-child) {
        @apply mr-6;
      }

      + li {
        @apply mt-0;
      }
    }
  }
}
