@import "base";
@tailwind components;

//colors
@import "partials/colors/solvari-primary";
@import "partials/colors/solvari-accent";
@import "partials/colors/solvari-gray";

//fonts
@import "partials/fonts/roboto-slab";
@import "partials/fonts/roboto";

//common
@import "solvari-dark/common/typography";
@import "solvari-light/common/typography";

//partials
@import "partials/above-meta";
@import "partials/archive/article-1";
@import "partials/layout/blockquote-fa-desktop";
@import "partials/bol";
@import "partials/cta/powered-by";
@import "partials/cta/solvari-list";
@import "partials/footer-complex";
@import "partials/locale-logic";
@import "partials/related-posts/related-posts-2";
@import "partials/navigation/menu-mobile-1";
@import "partials/navigation/menu-mobile-1-dropdown";

// components
@import "solvari-dark/components/accordion";
@import "solvari-dark/components/article";
@import "solvari-dark/components/banner";
@import "solvari-dark/components/buttons";
@import "solvari-dark/components/blockquotes";
@import "solvari-dark/components/embed";
@import "solvari-dark/components/footer";
@import "solvari-dark/components/list-fa-colors";
@import "solvari-dark/components/menu-desktop";
@import "solvari-dark/components/popup";
@import "solvari-dark/components/related-posts";
@import "solvari-dark/components/sidebar";
@import "solvari-light/components/banner";
@import "solvari-light/components/embed";
@import "solvari-light/components/footer";
@import "solvari-light/components/header/header-mobile";
@import "solvari-light/components/menu-desktop";
@import "solvari-light/components/rounded-list";
@import "solvari-light/components/tablepress";
@import "cv-inregelen/components/header-desktop";
@import "cv-inregelen/components/hydronic-balancing";
@import "cv-inregelen/components/webshop/checkout";
@import "cv-inregelen/components/webshop/inputs";
@import "cv-inregelen/components/webshop/single";
@import "cv-inregelen/components/webshop/shop";

//layouts
@import "dark/layouts/base";

//pages
@import "cv-inregelen/pages/home";

//utilities
@tailwind utilities;
