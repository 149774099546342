.page-container,
.mce-content-body,
.block-editor__typewriter {
  @apply bg-white;
}

.page-container {
  @apply flex flex-col min-h-screen;
}

.container {
  @apply max-w-full px-3 mx-auto w-screen-1200;
}

.content-container {
  @apply flex-grow flex justify-between;
}

html {
  font-size: 87.5%;
}

main {
  @apply self-start;
}

@screen 400 {
  html {
    font-size: 100%;
  }

  .container {
    @apply px-6;
  }
}

.layout-centered,
.layout-full-width,
.layout-sidebar,
.mce-content-body {
  @apply pt-16 pb-16 mb-8 max-w-full mx-auto relative;
}

.layout-full-width {
  @apply w-full;
}

.layout-sidebar {
  @apply flex-shrink min-w-0 w-3xl;

  @screen 900 {
    @apply mx-0;
  }
}

.layout-centered {
  @apply w-3xl;
}

.mce-content-body {
  &.page-template-template_full-width-blade {
    @apply w-screen-1200 max-w-full;
  }

  &.page-template-template_sidebar-blade,
  &.page-template-template_centered-blade,
  &.page-template-default {
    @apply w-3xl max-w-full;
  }
}

.not-indexable-banner {
  @apply fixed top-8 left-0 w-full bg-red-500 p-1 uppercase text-center text-sm font-bold text-white z-50;
}
