.bol-affiliate-product {
  @apply flex flex-col;

  > a {
    @apply w-full flex justify-center h-32 w-full;
  }

  &__img {
    @apply max-h-full w-auto max-w-32 mr-0 object-contain object-center;
  }

  &__title {
    @apply mt-2 mb-4;

    a {
      @apply no-underline overflow-hidden;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      &:hover {
        @apply underline;
      }
    }
  }

  &__price {
    @apply text-base mt-auto text-solvari-accent-500;
  }

  &-list {
    @apply grid grid-cols-auto-40 gap-y-24 gap-x-5 my-8 justify-center;
  }
}

.bol-affiliate-product-list__btn,
.bol-affiliate-text-link--btn {
  @apply btn my-4;

  @extend .btn-primary;
}
