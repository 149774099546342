@tailwind base;

//root
@import "base/search";
@import "base/archive";

// layouts
@import "base/layouts/base";

//common
@import "base/common/typography";
@import "base/components/tablepress";

// components
@import "base/components/article";
@import "base/components/buttons";
@import "base/components/cookies";
@import "base/components/embed";
@import "base/components/header/header-desktop";
@import "base/components/header/header-mobile";
@import "base/components/menu-desktop";
@import "base/components/menu-mobile";
@import "base/components/popup";
@import "base/components/search/pagination";
@import "base/components/search/searchbar";
@import "base/components/sidebar";
@import "base/components/layout/accordion";
@import "base/components/layout/blockquotes";
@import "base/components/layout/gallery";
@import "base/components/layout/rounded-list";

//pages
@import "base/pages/404";
@import "base/pages/success";
