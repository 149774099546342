ol.list\:rounded,
.list\:rounded ol {
  @apply list-none;
}

.list\:rounded {
  counter-reset: li;

  @apply p-6;

  ol {
    @apply pl-0 mb-0;
  }

  li {
    counter-increment: li;

    @apply pt-20 relative;

    & + li {
      @apply mt-12;
    }

    &::before {
      content: counter(li);
      left: 50%;
      transform: translateX(-50%);

      @apply w-16 h-16 text-3xl absolute top-0 flex items-center justify-center rounded-full font-bold;
    }

    @screen 600 {
      @apply pl-16 pt-0;

      &::before {
        top: 50%;
        transform: translateY(-50%);

        @apply left-0 w-12 h-12 text-2xl;
      }
    }
  }
}
