@media only screen and (max-width: 899px) {
  .menu--mobile {
    @apply overflow-y-scroll;

    &::-webkit-scrollbar {
      @apply border-none bg-white w-1.5;

      &-track {
        @apply rounded-full border-none bg-gray-100;
      }

      &-thumb {
        @apply rounded-full bg-primary-400;
      }
    }

    &__buttons {
      @apply list-none m-0 px-4 pt-8 overflow-visible;

      a {
        @apply btn w-full;
      }
    }

    .menu {
      &__item {
        @apply mt-0 grid;
        grid-template:
          "link"
          "submenu" /
          minmax(0, 1fr);

        &__arrow {
          @apply hidden;
        }

        &__link {
          @apply pr-4 no-underline text-gray-800 break-words;
          padding-left: var(--spacing-left);
          grid-area: link;

          &[href]:hover {
            @apply underline text-gray-800;
          }
        }

        &--depth-0 {
          --spacing-left: 2.5rem;

          &.menu__item--parent {
            @apply pb-3;
          }

          > .menu__item__link {
            @apply py-3 text-xl;
          }
        }

        &--depth-1 .menu__item__link {
          @apply py-2;
        }

        @for $i from 1 through 3 {
          &--depth-#{$i} {
            --spacing-left: #{($i + 1) * 1.25}rem;
          }
        }
      }

      &__sub,
      &__container {
        @apply list-none my-0 overflow-visible;
      }

      &__sub {
        @apply p-0;
        grid-area: submenu;
      }

      &__container {
        @apply px-0 pb-0 pt-8;
      }
    }
  }
}
