.pagination {
  @apply flex w-full justify-center;

  .screen-reader-text {
    position: absolute;
    left: -10000px;
    top: auto;
  }

  .nav-links {
    @apply flex;
  }

  a {
    @apply transition-colors no-underline text-gray-800;

    &:hover {
      @apply bg-gray-200;
    }
  }

  a,
  .current {
    @apply w-8 rounded;
  }

  .page-numbers {
    @apply flex items-center justify-center font-bold h-8 text-center text-sm;

    + .page-numbers {
      @apply ml-1;
    }
  }

  .dots {
    @apply text-gray-600 w-4;
  }

  .prev,
  .next {
    @apply border w-8 text-primary-500;
  }

  .current {
    @apply text-white bg-primary-500;
  }

  .svg-inline--fa,
  i {
    @apply text-lg;
  }
}
