@screen 900 {
  .menu--desktop {
    @apply w-full z-30;

    .menu {
      &__title__container,
      &--mobile__buttons {
        @apply hidden;
      }

      &__container {
        @apply flex h-auto min-h-12 items-stretch flex-wrap max-w-full px-6 mx-auto w-screen-1200;

        transform: none !important;
      }

      &__item {
        @apply flex;

        background-color: inherit;

        &--current > .menu__item__link {
          @apply transition-none;
        }

        &__arrow--dropdown {
          @apply hidden;
        }

        &__link {
          @apply border-l uppercase flex flex-grow items-center text-center transition-bg no-underline;

          background-color: inherit;
        }

        &--depth-0 {
          @apply min-h-12;

          &.menu__item--parent {
            @apply relative;

            &:hover {
              @apply drop-shadow;
            }
          }

          &:last-child .menu__sub--depth-1 {
            @apply right-0;

            .menu {
              &__item__arrow {
                @apply rotate-90;
              }

              &__sub {
                @apply right-full left-auto;
              }
            }
          }
        }

        &--parent > .menu__item__link {
          @apply justify-between;
        }
      }

      &__sub {
        @apply hidden absolute pl-0 grid-flow-col z-50 top-full;

        max-height: 40rem;
        grid-template-rows: repeat(auto-fit, 3rem);

        .menu__item {
          &__link {
            @apply border-l-0 border-t whitespace-nowrap normal-case;
          }

          &__arrow {
            @apply transform -rotate-90;
          }
        }

        &--depth-1 {
          @apply min-w-full;

          .menu__sub {
            @apply left-full top-0 min-h-full;
          }
        }
      }

      &__item--parent:hover > .menu__sub,
      &__sub:hover {
        @apply grid;
      }
    }

    ul {
      @apply my-0 list-none;

      background-color: inherit;
      overflow: visible !important;
    }

    li {
      @apply mt-0;
    }
  }
}

@media only screen and (max-width: 899px) {
  .menu--desktop:not(.menu--mobile) {
    @apply hidden;
  }
}
