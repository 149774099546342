.home {
  &__banner {
    @apply bg-gray-100 w-full py-24;

    &__container {
      @apply grid grid-cols-1 gap-6;

      @screen 900 {
        grid-template-columns: 2fr minmax(max-content, 1.5fr);
      }
    }

    &__right {
      @apply flex-shrink mx-auto flex flex-col justify-end;
    }

    &__title {
      @apply text-5xl mb-12;
    }

    &__list {
      li {
        @apply text-primary-500 text-xl pl-10;
      }

      i,
      .svg-inline--fa {
        @apply w-6 h-6;
      }
    }

    &__button {
      @apply w-full;

      font-size: 1.5rem!important;
      padding-bottom: 1rem!important;
      padding-top: 1rem!important;
    }

    &__slogan {
      @apply mt-4 text-xl text-center mb-0;
    }
  }

  &__discover {
    @apply flex flex-col items-center py-24;

    @screen 900 {
      @apply flex-row;
    }

    &__left {
      @apply w-128 max-w-full mb-8 flex-none;

      @screen 900 {
        @apply mr-16 mb-0;
      }
    }

    &__video {
      @apply flex-grow;
    }
  }

  &__bereken {
    @apply py-24 bg-gray-100;

    &__container {
      @apply flex flex-col items-center;
    }

    &__title {
      @apply text-center mb-8 text-3xl;
    }

    &__grid {
      @apply grid grid-cols-auto-grow-fit-64 gap-8 mb-16 w-full;
    }

    &__example {
      @apply bg-white shadow p-4 rounded flex flex-col items-center;

      &__img {
        @apply w-full h-64 object-contain mb-4;
      }

      &__label {
        @apply text-xl text-center mb-8 flex-grow font-roboto-slab;
      }

      &__save {
        @apply text-accent-500 text-center text-lg;
      }

      &__price {
        @apply text-accent-500 text-4xl font-roboto-slab;
      }
    }

    &__text {
      @apply max-w-128 text-center text-lg;
    }
  }

  &__percentage {
    @apply flex flex-col items-center py-24;

    @screen 900 {
      @apply flex-row;
    }

    &__left {
      @apply w-128 max-w-full mb-8 flex-none;

      @screen 900 {
        @apply mr-16 mb-0;
      }
    }

    &__image {
      @apply w-96 mx-auto drop-shadow-md max-w-full;
    }
  }
}
