.searchbar {
  @apply max-w-full w-80 h-auto bg-white inline-flex flex-nowrap;

  &__label {
    position: absolute;
    left: -10000px;
    top: auto;
  }

  &__input {
    @apply flex-grow h-10 px-3 bg-white outline-none transition-bg truncate rounded-none py-0;

    font-size: 1.125rem !important;
    border: 1px solid #d5dcdd !important;
    border-right-width: 0 !important;

    &:hover {
      @apply bg-gray-100;
    }

    &:focus {
      @apply bg-gray-100;

      &::placeholder {
        @apply text-gray-300;
      }
    }

    &::placeholder {
      @apply transition-text text-primary-500;
    }
  }

  &__button {
    @apply w-10 h-10 text-white transition-bg flex-none rounded-none shadow-none text-lg bg-accent-500;

    margin-left: 0 !important;

    .svg-inline--fa {
      min-height: 1rem !important;
      min-width: 1rem !important;
    }

    &:hover {
      @apply bg-accent-600;
    }
  }

  &.searchbar--rounded {
    @apply p-1 rounded-full shadow;

    .searchbar {
      &__input {
        @apply rounded-full mr-1;

        border: 0 !important;
      }

      &__button {
        @apply rounded-full;
      }
    }
  }
}
