@layer components {
  .btn {
    @apply px-8 py-4 rounded cursor-pointer text-center transition-bg text-xl inline-block leading-none no-underline border-none outline-none text-white;

    &:hover {
      @apply text-white;
    }

    &--medium {
      @apply p-3 text-sm font-bold tracking-wide uppercase;
    }

    &--small {
      @apply p-2 text-base font-bold tracking-wide shadow;
    }

    &-primary {
      @apply bg-primary-500;

      &:hover {
        @apply bg-primary-400;
      }
    }

    &-accent {
      @apply bg-accent-500;

      &:hover {
        @apply bg-accent-600;
      }
    }

    &-accent-2 {
      @apply bg-green-500;

      &:hover {
        @apply bg-green-400;
      }
    }

    &-accent-3 {
      background-color: #f59b00;

      &:hover {
        background-color: #ffaf14;
      }
    }
  }
}
