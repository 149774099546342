@screen 900 {
  .menu--desktop {
    @apply bg-white;

    .menu {
      &__container {
        @apply min-h-10;
      }

      &__item {
        &__link {
          @apply border-gray-200 px-8 normal-case text-primary-500;
        }

        &--depth-0 {
          @apply min-h-10;
        }

        &__link:hover,
        &--parent:hover > .menu__item__link,
        &--current > .menu__item__link {
          @apply bg-gray-200;
        }

        &--parent > .menu__item__link {
          @apply pr-4;
        }
      }

      &__sub {
        @apply bg-white;

        grid-template-rows: repeat(auto-fit, 2.5rem);
      }
    }

    i,
    .svg-inline--fa {
      @apply w-4 h-4 ml-4;
    }
  }
}
