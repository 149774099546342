#dynamic-form-container .container-fluid {
  .card {
    @apply rounded;
  }

  .card-header {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .btn-buldit-form-submit {
    @extend .btn-primary;
  }
}
