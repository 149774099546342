.woocommerce {
  .wc-proceed-to-checkout {
    @apply flex;
  }

  .checkout-button {
    @apply mt-4 ml-auto btn;

    @extend .btn-primary;
  }

  .shop_table {
    thead {
      @apply hidden;
    }

    th {
      @apply bg-transparent text-gray-800 font-bold;
    }

    .remove {
      @apply w-8 h-8 text-3xl flex items-center justify-center no-underline text-primary-500 bg-gray-200 rounded-full;
    }

    .attachment-woocommerce_thumbnail,
    .attachment-woocommerce_thumbnail img {
      @apply w-40 h-40 max-w-40 max-h-40;
    }

    .button {
      @apply py-2 text-primary-500 transition-text underline;

      &:hover {
        @apply text-primary-400;
      }
    }

    .actions > .button {
      @apply ml-auto block;
    }

    .coupon {
      @apply flex justify-start items-center;

      .input-text {
        @apply mr-4;
      }

      label {
        @apply hidden;
      }
    }

    .product-quantity .input-text {
      @apply w-24;
    }
  }

  .cart-collaterals {
    @apply mt-8;
  }

  .woocommerce-cart-form {
    @apply overflow-x-auto max-w-full my-4;

    &::-webkit-scrollbar {
      height: 6px;

      @apply border-none bg-white;

      &-track {
        @apply bg-gray-100 rounded-full border-none;
      }

      &-thumb {
        @apply rounded-full bg-primary-400;
      }
    }
  }

  &-cart {
    &-icon {
      @apply bg-primary-500 w-12 h-12 drop-shadow rounded-full fixed bottom-3 right-3 text-xl text-white flex items-center justify-center z-10 transition-bg;

      @screen 400 {
        @apply bottom-6 right-6;
      }

      &:hover {
        @apply bg-primary-400 text-white;
      }
    }

    &-amount {
      @apply bg-red-500 w-6 h-6 text-white rounded-full flex items-center justify-center font-bold absolute -right-1 -top-1 text-sm;
    }
  }

  .woocommerce-shipping-calculator {
    @apply hidden;
  }

  .woocommerce-billing-fields__field-wrapper,
  .woocommerce-shipping-fields__field-wrapper {
    @apply grid grid-cols-auto-grow-80 gap-6 mb-8;

    .input-text {
      @apply w-full;
    }

    .select2-selection--single {
      @apply border-0 h-auto m-0;
    }

    .select2-selection__rendered {
      line-height: 1.5 !important;
    }

    .select2-selection__arrow {
      height: 100% !important;
    }
  }

  #order_review_heading {
    @apply mt-8;
  }

  .woocommerce-checkout .input-text {
    @apply w-full;
  }

  .woocommerce-checkout-payment button {
    @apply w-full btn btn--small;

    @extend .btn-primary;
  }

  .form-row {
    @apply flex flex-col justify-end mb-0;

    label {
      @apply mb-2;
    }
  }

  .wc_payment_methods,
  .woocommerce-shipping-methods {
    @apply list-none;
  }

  abbr[title] {
    @apply no-underline;
  }
}
