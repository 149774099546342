.related-post {
  @apply mt-24;

  grid-column: wide !important;

  &__heading {
    @apply text-3xl block text-center;
  }

  &__container {
    @apply grid grid-cols-auto-grow-56 gap-6 my-8;
  }

  &__item {
    @apply no-underline flex flex-col shadow duration-300 transform bg-white;

    transition-property: box-shadow, transform;

    &:hover {
      @apply no-underline -translate-y-2 shadow;
    }
  }

  &__category {
    @apply self-center mx-4 uppercase text-sm flex items-center min-h-6 -mt-3 px-3 rounded-full no-underline;

    max-width: calc(100% - 2rem);

    > span {
      @apply max-w-full truncate;
    }
  }

  &__title {
    @apply text-lg px-4 pt-4 pb-6 text-center transition-text;
  }

  &__img {
    @apply w-full min-h-40 h-0 flex-grow;

    img {
      @apply w-full h-full object-cover object-center;
    }
  }
}
