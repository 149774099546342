html {
  -webkit-font-smoothing: antialiased !important;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  @apply block;
}

h1,
.h1 {
  @apply text-3xl mb-5;
}

h2,
.h2 {
  @apply text-2xl mb-4;
}

h3,
.h3 {
  @apply text-xl mb-3;
}

h4,
.h4 {
  @apply text-lg mb-2;
}

p {
  @apply mb-4 min-h-6;
}

p,
li,
em,
.blockquote-content {
  @apply text-lg text-gray-800;

  @screen 400 {
    @apply text-base;
  }
}

strong {
  @apply font-bold;

  color: inherit;
}

dt {
  @apply underline;
}

dd {
  @apply mb-2;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

[data-fitty] {
  @apply inline-flex whitespace-nowrap;
}
