.sidebar {
  &::-webkit-scrollbar {
    width: 6px;

    @apply border-none bg-white;

    &-track {
      @apply bg-gray-100 rounded-full border-none;
    }

    &-thumb {
      @apply rounded-full bg-primary-400;
    }
  }

  @screen 900 {
    @apply hidden ml-12 my-16 w-80 flex-none transform-none;

    &-desktop {
      @apply block;
    }
  }

  &__nav {
    @apply text-gray-600 px-4 py-8 mb-8;

    + .sidebar__nav {
      @apply mt-8;
    }

    h3 {
      @apply uppercase;
    }

    h4 {
      @apply text-lg;
    }

    ul {
      @apply list-none my-0 pl-0 overflow-visible;
    }

    a {
      @apply py-1.5 no-underline text-gray-800;

      &:hover {
        @apply underline text-gray-800;
      }
    }

    li + li {
      @apply mt-2;
    }

    &--border {
      @apply border-l-4;
    }
  }
}

@media only screen and (max-width: 899px) {
  .sidebar:not(.menu--mobile) {
    @apply hidden;
  }
}
