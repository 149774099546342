html {
  --gray-100: #f3f7f9;
  --gray-200: #e5e5ee;
  --gray-300: #c9c9d1;
  --gray-400: #aeaeb6;
  --gray-500: #93939b;
  --gray-600: #797981;
  --gray-700: #606168;
  --gray-800: #505760;
  --gray-900: #323239;
}
