.woocommerce {
  .summary {
    @apply flex flex-col self-center;

    grid-area: summary;

    .cart {
      @apply flex mt-8;

      input {
        @apply w-32 mr-4;
      }
    }

    .single_add_to_cart_button {
      @apply flex-grow;
    }

    .product_meta {
      @apply hidden;
    }

    .price {
      @apply text-3xl font-normal mb-4;
    }
  }

  .screen-reader-text {
    @apply hidden;
  }

  .woocommerce-product-gallery {
    @apply p-4 rounded bg-gray-100 relative self-center;

    grid-area: gallery;

    &__trigger {
      @apply flex items-center justify-center absolute right-8 top-8 text-lg w-10 h-10 bg-white rounded-full z-10 no-underline shadow transition-bg;

      &:hover {
        @apply bg-gray-200;
      }
    }

    img {
      @apply w-full rounded;
    }

    .flex-control-nav {
      @apply grid grid-cols-auto-grow-24 gap-4 list-none p-0 mb-0;

      li {
        @apply m-0;
      }
    }
  }

  .woocommerce-message {
    @apply mb-4 p-4 bg-gray-100 rounded border-l-4 border-accent-500;

    .button {
      @apply btn btn--small mr-4;

      @extend .btn-primary;
    }
  }

  .single-product > .product {
    @apply grid gap-16 grid-cols-1;

    grid-template-areas: "summary" "gallery";

    @screen 900 {
      @apply grid-cols-2;

      grid-template-areas: "gallery summary";
    }
  }

  .woocommerce-tabs,
  .related {
    @apply col-span-full;
  }

  &-tabs {
    .tabs {
      list-style: none;
      padding: 0 0 0 1em;
      margin: 0 0 1.618em;
      overflow: hidden;
      position: relative;

      li {
        border: 1px solid #d3ced2;
        background-color: #ebe9eb;
        display: inline-block;
        position: relative;
        z-index: 0;
        border-radius: 4px 4px 0 0;
        margin: 0 -5px;
        padding: 0 1em;
      }

      li a {
        display: inline-block;
        padding: 0.5em 0;
        font-weight: 700;
        color: #515151;
        text-decoration: none;
      }

      li a:hover {
        text-decoration: none;
        color: #6b6a6b;
      }

      li.active {
        background: #fff;
        z-index: 2;
        border-bottom-color: #fff;
      }

      li.active a {
        color: inherit;
        text-shadow: inherit;
      }

      li.active::before {
        box-shadow: 2px 2px 0 #fff;
      }

      li.active::after {
        box-shadow: -2px 2px 0 #fff;
      }

      li::after,
      li::before {
        border: 1px solid #d3ced2;
        position: absolute;
        bottom: -1px;
        width: 5px;
        height: 5px;
        content: " ";
        box-sizing: border-box;
      }

      li::before {
        left: -5px;
        border-bottom-right-radius: 4px;
        border-width: 0 1px 1px 0;
        box-shadow: 2px 2px 0 #ebe9eb;
      }

      li::after {
        right: -5px;
        border-bottom-left-radius: 4px;
        border-width: 0 0 1px 1px;
        box-shadow: -2px 2px 0 #ebe9eb;
      }

      &::before {
        position: absolute;
        content: " ";
        width: 100%;
        bottom: 0;
        left: 0;
        border-bottom: 1px solid #d3ced2;
        z-index: 1;
      }
    }

    .panel {
      margin: 0 0 2em;
      padding: 0;
    }
  }
}
