.footer__bottom {
  @apply w-full py-16 content-visibility-auto contain-intrinsic-size-64;

  &__nav {
    @apply grid grid-cols-1 mb-8 justify-items-center;

    ul {
      @apply my-0 pl-0 list-none;
    }

    li {
      @apply flex mt-0;
    }
  }

  .accordion__title {
    @apply text-lg uppercase font-normal;

    i,
    .svg-inline--fa {
      @apply w-4 h-4 mr-0 ml-auto;
    }
  }

  &__list {
    @apply w-96 max-w-full;

    a {
      @apply normal-case transition-text py-2 mt-0 no-underline;

      &:hover {
        @apply underline;
      }
    }
  }

  &__social {
    @apply mt-8 flex;

    &__button {
      @apply w-12 h-12 drop-shadow transition-filter text-3xl bg-white;

      a {
        @apply flex items-center justify-center w-full h-full;
      }

      &:hover {
        @apply drop-shadow;
      }

      + .footer__bottom__social__button {
        @apply ml-4;
      }
    }
  }
}

@screen 700 {
  .footer__bottom {
    &__nav {
      @apply gap-x-8;

      grid-template-columns: minmax(24rem, 1fr) 1fr;
    }

    &__list {
      @apply col-start-1 justify-self-start;
    }

    &__social {
      @apply row-start-1 row-end-auto col-start-2 mt-0 justify-self-end;
    }
  }
}

@screen 1000 {
  .footer__bottom {
    &__nav {
      @apply grid gap-8 grid-cols-auto-grow-fit-40;

      .footer__bottom__list {
        @apply max-w-full;
      }
    }

    .accordion {
      &__title {
        @apply text-xl select-auto pointer-events-none py-0;

        i,
        .svg-inline--fa {
          @apply hidden;
        }
      }

      &__content {
        @apply h-auto overflow-auto;

        padding-bottom: 0 !important;
      }
    }

    &__list {
      @apply col-auto;

      &__item + &__item {
        @apply mt-8;
      }
    }

    &__social {
      @apply row-auto col-auto;
    }
  }
}
