[data-gallery] img {
  @apply cursor-pointer;
}

.gallery {
  &__container {
    @apply grid grid-cols-auto-grow-36 gap-8 my-8;

    > img {
      @apply cursor-pointer;
    }
  }

  &__popup {
    @apply bg-black overflow-visible w-6xl self-center;

    img {
      @apply min-h-0 min-w-0 max-h-full max-w-full w-full h-full object-contain relative;
    }

    .popup__close-button {
      @apply top-3 right-3;
    }

    &__next-button,
    &__previous-button {
      top: calc(50% - 4rem);

      @apply h-32 w-20 z-10 absolute flex items-center justify-center leading-none opacity-50 transition-opacity;

      i,
      .svg-inline--fa {
        @apply inline-block h-6 w-6 text-white drop-shadow transition-filter;
      }

      &:hover {
        @apply opacity-100;

        .svg-inline--fa {
          @apply drop-shadow;
        }
      }
    }

    &__next-button {
      right: -1rem;
    }

    &__previous-button {
      left: -1rem;
    }

    @screen 1300 {
      &__next-button,
      &__previous-button {
        @apply opacity-75;
      }

      &__next-button {
        right: -4rem;
      }

      &__previous-button {
        left: -4rem;
      }
    }
  }
}
