#dynamic-form-container {
  min-height: 1300px;

  > .container-fluid {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

#dynamic-form-container .container-fluid {
  .card-header {
    @apply text-xl py-4 border-none bg-primary-500 text-white;
  }

  textarea,
  input[type="text"],
  input[type="email"] {
    @apply bg-white transition-bg;

    &:hover {
      @apply bg-gray-100;
    }

    &::placeholder {
      @apply transition-text;
    }

    &:focus {
      @apply bg-gray-100;

      &::placeholder {
        @apply text-gray-300;
      }
    }
  }

  .card:first-child {
    .form-group {
      @apply mb-8;
    }

    .card-block {
      @apply pb-0;
    }

    .col-form-label {
      @apply p-0;
    }
  }

  small {
    @apply text-base;

    @screen 400 {
      @apply text-sm;
    }
  }

  .col-form-label {
    @apply text-lg mb-2 text-primary-500;

    + small {
      @apply mt-0;

      color: #2376bd !important;
      margin-bottom: 0.5rem !important;
    }
  }

  .buldit-radio-question,
  .buldit-checkbox-question {
    .col-form-label {
      @apply mb-0;
    }
  }

  .form-check {
    @apply mb-0;

    &-label {
      @apply text-gray-700 py-2 inline-flex items-center;
    }

    &-input {
      @apply mt-0 flex items-center justify-center;

      &::before {
        content: "";

        @apply absolute w-8 h-8;
      }
    }
  }

  .btn-buldit-form-submit {
    @apply btn;
  }
}

.popover {
  margin-top: -1rem;

  @apply p-3 border rounded max-w-72 bg-white text-sm;

  &-title {
    @apply mb-0;
  }

  &::after {
    content: "";
    left: 50%;
    bottom: calc(-1rem + 2px);
    transform: rotateZ(45deg) translateX(-50%);

    @apply bg-white w-4 h-4 border-r border-b absolute;
  }
}
