.article__above-meta {
  @apply -mt-12 h-12 flex items-start w-full;

  > ol {
    @apply hidden flex-wrap list-none m-0 p-0 overflow-visible;

    @screen 600 {
      @apply flex;
    }
  }
}

.breadcrumb {
  &__item {
    @apply mt-0 text-sm leading-none flex items-center;

    + .breadcrumb__item::before {
      @apply w-6 flex justify-center text-gray-500;
      content: "/";
    }

    &--last {
      @apply text-gray-700;
    }
  }

  &__link {
    @apply no-underline -m-1 p-1 block bg-transparent transition-colors rounded border border-transparent;

    &:hover {
      @apply text-primary-500 bg-gray-100 border-gray-200;
    }
  }

  &__icon.svg-inline--fa {
    @apply w-1em h-1em block;
  }
}
