.footer {
  &__top {
    @apply w-full py-16 content-visibility-auto contain-intrinsic-size-40;

    background-image: linear-gradient(150deg, #13568b, #2770b7 85%);

    &__container {
      @apply flex flex-col items-center;
    }

    &__content {
      @apply mt-12 flex-grow;
    }

    @screen 800 {
      &__container {
        @apply grid items-end gap-6;

        grid-template-columns: minmax(28.5rem, 1fr) 1fr;
      }

      &__content {
        @apply mt-0;
      }
    }
  }

  &__bottom {
    @apply bg-white;

    &__disclaimer {
      @apply text-gray-800;
    }

    &__list a {
      @apply text-gray-800;

      &:hover {
        @apply text-gray-900;
      }
    }

    .accordion__title {
      @apply normal-case;
    }

    &__social__button {
      @apply shadow rounded transition-shadow;

      filter: none;

      a {
        clip-path: none;
      }

      &:hover {
        @apply shadow-md;

        filter: none;
      }
    }
  }
}
