table {
  @apply text-center w-full;

  th {
    @apply px-2 py-3 font-normal bg-primary-500 text-white;

    a {
      color: inherit;
    }
  }

  tr:nth-child(even) {
    @apply bg-gray-100;
  }

  td {
    @apply px-2 py-3;
  }

  img {
    @apply max-w-128;
  }
}

.tablepress-overflow-container {
  @apply overflow-x-auto max-w-full my-4;

  &::-webkit-scrollbar {
    height: 6px;

    @apply border-none bg-white;

    &-track {
      @apply bg-gray-100 rounded-full border-none;
    }

    &-thumb {
      @apply rounded-full bg-primary-400;
    }
  }
}
