blockquote {
  @apply px-5 py-6 text-gray-700 font-normal border-l-6 bg-gray-100 border-primary-500;

  p:last-child {
    @apply mb-0;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply text-primary-500;
  }
}

.blockquote {
  &-fa {
    @apply p-0 flex flex-col mt-16 border-0;
  }

  &-icon {
    @apply rounded-full w-20 h-20 mx-auto -mt-10 flex justify-center items-center flex-none font-bold text-2xl uppercase bg-primary-500 text-white;

    .svg-inline--fa,
    i {
      @apply h-10 w-10;
    }
  }

  &-content {
    @apply py-6 px-5;
  }

  &-cta .blockquote {
    &-content {
      @apply flex items-center justify-center flex-grow text-center font-bold text-primary-500;

      font-size: 1.4rem;
    }

    &-btn {
      @apply px-5 pb-6 flex items-center justify-center flex-none;
    }
  }
}
